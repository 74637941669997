import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { withQuoteWizard } from '../components/withQuoteWizard';
import SEO from '../components/SEO';
import ProjectLayout from '../sections/ProjectLayout/ProjectLayout';
import ProjectHighlight from '../sections/ProjectHighlight/ProjectHighlight';
import ProjectFeature from '../sections/ProjectFeature/ProjectFeature';
import ProjectTransformation from '../sections/ProjectTransformation/ProjectTransformation';
import SimilarProjects from '../sections/SimilarProjects/SimilarProjects';
import { getElements } from '../helpers/functions';
import { similarProjects } from '../helpers/consts';

//Images
import {DMHendersonParkImages, SingleProjectsIcons} from '../imageURLs';

const pageData = {
  heroImage: DMHendersonParkImages.hero,
  projectTitle: 'D.M. Henderson Park',
  projectSubtitle: "Brisbane City Council",
  serviceShortInfo: [
    {
      icon: SingleProjectsIcons.icon1,
      type: "Service",
      text: "Redevelopment"
    },
    {
      icon: SingleProjectsIcons.icon2,
      type: "Type",
      text: "Rugby Field"
    },
    {
      icon: SingleProjectsIcons.icon3,
      type: "Turf type",
      text: "Nullarbor Couch"
    },
    {
      icon: SingleProjectsIcons.icon4,
      type: "Handover",
      text: "April 2022"
    },
    {
      icon: SingleProjectsIcons.icon5,
      type: "Value",
      text: "$230k"
    },
  ],
  serviceDescription: 'A Rugby Union field redevelopment completed with premium sports turf and established with a customised maintenance program.',
  serviceListItems: [
    {
      value: '7,000m2',
      text: 'of premium Nullarbor Couch turf'
    },
    {
      value: '560t',
      text: 'of imported top soil'
    },
    {
      value: '320lm',
      text: 'of site fencing'
    },
  ],
  featureImage: DMHendersonParkImages.feature,
  keyProcesses: [
    {
      title: "Site establishment",
      desc: "Field was fenced, surveys conducted and environmental and safety control plans were initiated under council protocol."
    },
    {
      title: "Ground preparation",
      desc: "Existing grass was killed off using multiple herbicide applications, then removed with a topmaker."
    },
    {
      title: "Soil Amendments",
      desc: "Soil amendment products were rotary hoed and blecevated into the profile. The subgrade was then laser levelled within a +/- 10mm variance."
    },
    {
      title: "Irrigation",
      desc: "Audit and service of irrigation system which required the replacement of faulty sprinkler heads and valve boxes."
    },
    {
      title: "Final Levels",
      desc: "Imported premium top soil was GPS laser levelled to achieve specified shape and design."
    },
    {
      title: "Turf Installation",
      desc: "7000m2 of premium Nullarbor Couch was installed, rolled then treated with OxaMax fertiliser and pre-emergent."
    },
    {
      title: "Maintenance",
      desc: "8 week intensive maintenance program consisting of mowing, top dressing, aerating, fertilising and pest and weed control including line marking."
    },
  ],
  featureTitle: 'Premium Turf Installation',
  featureHighlights: [
    "Premium sports turf variety renowned for its high performance",
    "Harvested fresh hours before delivery to ensure uncompromised quality",
    "AusGap Certified – guarantees genetic purity for a premium playing surface",
    "Professional installation to ensure efficient turf establishment and a uniform finish",
  ],
  // featureThumbnail: DMHendersonParkImages.thumb,
  transformationImages: [
    {
      imagePath: DMHendersonParkImages.slide1
    },
    {
      imagePath: DMHendersonParkImages.slide2
    },
  ],
  similarProjects: getElements(similarProjects, [1,2,3,4,5,6,8,9])
}

const DMHendersonParkPage = () => {
  return (
    <React.Fragment>
      <SEO
        title="D.M. Henderson Park - Twin View Turf - Producer of Australia’s Best Lawns"
        keywords={[
          `Turf care guides`,
          `care guides`,
          `Turf`,
          `Lawns`,
          `Best Lawns`,
          `Australia’s Best Lawns`,
          `lawn producer`,
        ]}
      />
      <ProjectLayout>
        <ProjectHighlight 
          heroImage={pageData?.heroImage} 
          projectTitle={pageData.projectTitle} 
          projectSubtitle={pageData.projectSubtitle} 
          serviceShortInfo={pageData.serviceShortInfo}
          serviceDescription={pageData.serviceDescription} 
          serviceListItems={pageData.serviceListItems}
         />
        <ProjectFeature 
          featureImage={pageData?.featureImage}
          keyProcesses={pageData?.keyProcesses} 
          featureTitle={pageData?.featureTitle}
          featureHighlights={pageData.featureHighlights} 
          featureCtaHref={'/sports-turf'} 
          featureThumbnail={pageData?.featureThumbnail} />
        <ProjectTransformation 
          transformationSlider={pageData.transformationImages}
         />
        <SimilarProjects cards={pageData.similarProjects} />
      </ProjectLayout>
    </React.Fragment>
  )
}

export default withQuoteWizard(withStyles({ withTheme: true })(DMHendersonParkPage));